<template>
  <box :title="title" :open="open" :loading="isLoading">
    <template slot="box-tools-right" v-if="$slots['box-tools-right']">
      <slot name="box-tools-right"/>
    </template>

    <template slot="pre-content">
      <div class="pull-left">
        <btn href="#" color="default" icon="fa-refresh" @click.native.prevent="fetchData">Refresh</btn>
      </div>
      <div class="pull-right" v-if="paginationVisible">
        <simple-pagination :data="paginationData" @page-changed="pageChanged"/>
      </div>
    </template>

    <template slot="content">
      <div class="table-responsive no-box-padding">
        <table class="table table-hover">
          <thead>
            <slot name="table-header"/>
          </thead>
          <tbody v-if="hasResults">
            <slot name="table-body"/>
          </tbody>
          <tfoot v-if="hasResults">
            <slot name="table-footer"/>
          </tfoot>
        </table>

        <p class="mt10 text-center text-red" v-if="!hasResults">
          <strong>No Results Found</strong>
        </p>
      </div>
    </template>

    <template slot="post-content" v-if="paginationVisible">
      <div class="pull-right">
        <simple-pagination :data="paginationData" @page-changed="pageChanged"/>
      </div>
    </template>
  </box>
</template>

<script>
import Btn from '@/views/components/simple/Btn'
import SimplePagination from '@/views/components/SimplePagination'

export default {
  name: 'BoxTable',
  components: {
    Btn,
    SimplePagination
  },
  props: {
    title: { type: String, default: 'Info' },
    loader: { type: Boolean, default: false },
    pagination: { type: Boolean, default: true },
    dataProp: { type: String, required: true },
    dataCallback: { type: Function, default: undefined },
    url: { type: String, required: true },
    urlQuery: { type: Object, default: undefined }
  },
  computed: {
    isLoading () { return this.loader ? this.paginationData.disabled : false },
    hasResults () { return (this.$parent[this.dataProp] || []).length > 0 }
  },
  data () {
    return {
      open: !this.loader,
      paginationVisible: this.pagination,
      paginationData: {
        disabled: true,
        current: 1,
        first: '',
        prev: '',
        next: ''
      }
    }
  },
  created () {
    this.fetchData()
  },
  methods: {
    pageChanged (page) {
      this.paginationData.current = page
      this.fetchData()
    },
    fetchData () {
      this.beforeRequest()

      const query = {
        ...this.urlQuery,
        page: this.paginationData.current
      }

      this.$http
        .get(this.url, { params: query })
        .then(this.processResponse)
        .catch(this.processError)
        .finally(this.afterRequest)
    },
    beforeRequest () {
      this.$emit('request-before')

      this.paginationData.disabled = true

      if (!this.loader) this.$parent[this.dataProp] = []
    },
    afterRequest () {
      this
        .$nextTick()
        .then(() => {
          this.open = true
          this.paginationData.disabled = false
        })

      this.$emit('request-after')
    },
    processResponse (res) {
      const {
        data,
        links: { first, next, prev },
        meta: { current_page: current }
      } = res.data

      this.$parent[this.dataProp] = this.dataCallback ? this.dataCallback(data) : data

      this.paginationData.current = current
      this.paginationData.first = first
      this.paginationData.prev = prev
      this.paginationData.next = next

      if (!prev && !next) this.paginationVisible = false
    },
    processError (err) {
      const { response: { data: { message } } } = err

      this.$emit('request-error', message)
    }
  }
}
</script>
