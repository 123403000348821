<template>
  <box :title="title" :open="open" :loading="loading">
    <template v-if="request.success">
      <template slot="pre-content">
        <slot name="pre-content"/>
      </template>
      <template slot="content">
        <slot name="content"/>
      </template>
      <template slot="post-content">
        <slot name="post-content"/>
      </template>
    </template>
    <template slot="content" v-else>
      <p class="mt10 text-center text-red">
        <strong v-if="request.notFound">No Data Found</strong>
        <strong v-else>Unexpected Error Occurred</strong>
      </p>
    </template>
  </box>
</template>

<script>
export default {
  name: 'BoxSingle',
  props: {
    title: { type: String, default: 'Info' },
    loader: { type: Boolean, default: false },
    url: { type: String, required: true },
    dataProp: { type: String, default: undefined },
    respProp: { type: String, default: undefined },
    urlQuery: { type: Object, default: undefined },
    dataCallback: { type: Function, default: undefined }
  },
  data () {
    return {
      open: !this.loader,
      loading: this.loader,
      request: {
        success: false,
        notFound: false
      }
    }
  },
  created () {
    this.fetchData()
  },
  methods: {
    fetchData () {
      this.beforeRequest()

      const query = this.urlQuery ?? null

      this.$http
        .get(this.url, { params: query })
        .then(this.processResponse)
        .catch(this.processError)
        .finally(this.afterRequest)
    },
    beforeRequest () {
      this.$emit('request-before')
    },
    processResponse (res) {
      const { data } = res

      this.dataCallback
        ? this.dataCallback(data)
        : this.$parent[this.dataProp] = this.respProp
          ? data[this.respProp]
          : data

      this.request.success = true
    },
    processError (err) {
      const { response: { status, data: { message } } } = err

      if (status === 404) this.request.notFound = true

      this.$emit('request-error', { status: status, message: message })
    },
    afterRequest () {
      if (this.loader) {
        this.open = true
        this.loading = false
      }

      this.$emit('request-after', this.request)
    }
  }
}
</script>
