<template>
  <ul class="pagination pagination-sm clearfix no-margin">
    <li class="page-item" :class="{ 'disabled': isFirstDisabled }">
      <a href="#" aria-label="Previous" class="page-link" tabindex="-2" @click.prevent="first">
        <span>First</span>
      </a>
    </li>
    <li class="page-item" :class="{ 'disabled': isPrevDisabled }">
      <a href="#" aria-label="Previous" class="page-link" tabindex="-1" @click.prevent="prev">
        <span>Prev</span>
      </a>
    </li>
    <li class="page-item active">
      <a href="#" class="page-link" disabled="disabled" @click.prevent>
        <span>{{ data.current }}</span>
        <span class="sr-only">(current)</span>
      </a>
    </li>
    <li class="page-item" :class="{ 'disabled': isNextDisabled }">
      <a href="#" aria-label="Next" class="page-link" @click.prevent="next">
        <span>Next</span>
      </a>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'SimplePagination',
  props: {
    data: {
      type: Object,
      default: () => {
        return {
          page: 1,
          disabled: false,
          first: '',
          prev: '',
          next: ''
        }
      }
    }
  },
  computed: {
    isFirstDisabled () { return (this.data.disabled || !this.data.prev) },
    isPrevDisabled () { return (this.data.disabled || !this.data.prev) },
    isNextDisabled () { return (this.data.disabled || !this.data.next) }
  },
  methods: {
    first () {
      if (this.isFirstDisabled) return

      this.$emit('page-changed', 1)
    },
    prev () {
      if (this.isPrevDisabled) return

      this.$emit('page-changed', this.data.current - 1)
    },
    next () {
      if (this.isNextDisabled) return

      this.$emit('page-changed', this.data.current + 1)
    }
  }
}
</script>

<style lang="scss">
.pagination {
  display: block;

  li.disabled {
    a, a:focus, a:hover,
    span, span:focus, span:hover {
      background-color: #ddd;
    }
  }
}
</style>
