<template>
  <span class="label" :class="color" :title="title">{{ text }}</span>
</template>

<script>
export default {
  name: 'BadgeActive',
  props: {
    value: { type: Boolean, default: undefined }
  },
  computed: {
    color () {
      if (typeof this.value === 'undefined') return 'bg-gray'
      return this.value ? 'bg-green' : 'bg-red'
    },
    text () {
      if (typeof this.value === 'undefined') return 'None'
      return this.value ? 'Yes' : 'No'
    },
    title () {
      if (typeof this.value === 'undefined') return 'None'
      return this.value ? 'Active' : 'Inactive'
    }
  }
}
</script>
