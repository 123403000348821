<template>
  <div>
    <portal to="page-top-title">Category #{{ id }}</portal>
    <portal to="page-top-right">
      <btn :route="{ name: 'badwords-categories-edit', params: { id: id } }" icon="fa-edit" text>Edit</btn>
    </portal>

    <box-single :url="`/badwords/categories/${id}`" data-prop="category" loader resp-prop="data">
      <template slot="content">
        <row>
          <column-info title="Name">{{ category.name }}</column-info>
          <column-info title="Active">
            <badge-active :value="category.active"/>
          </column-info>
          <column-info title="Created At">{{ category.created_at }}</column-info>
          <column-info title="Updated At">{{ category.updated_at }}</column-info>
        </row>
        <row>
          <column-info title="Badwords">
            <badge title="Total">{{ category.badwords_count_total }}</badge>
            <badge title="Active" color="green">{{ category.badwords_count_active }}</badge>
            <badge title="Inactive" color="red">{{ category.badwords_count_inactive }}</badge>
          </column-info>
        </row>
      </template>
    </box-single>

    <box-table title="Badwords" :url="`/badwords`" :url-query="{ category_id: id }" data-prop="badwords" loader>
      <template slot="box-tools-right">
        <btn :route="{ name: 'badwords-new', query: { category: id } }" size="xs" icon="fa-plus" text>Add New</btn>
      </template>
      <template slot="table-header">
        <tr>
          <th>ID</th>
          <th>Value</th>
          <th class="text-center">Active</th>
          <th class="text-right">Created At</th>
          <th class="text-right">Updated At</th>
          <th class="text-right"></th>
        </tr>
      </template>
      <template slot="table-body">
        <table-row v-for="badword in badwords" :key="`badwords-tr-${badword.id}`" :value="badword">
          <td>{{ badword.id }}</td>
          <td>{{ badword.value }}</td>
          <td class="text-center">
            <badge-active :value="badword.active"/>
          </td>
          <td class="text-right">{{ badword.created_at }}</td>
          <td class="text-right">{{ badword.updated_at }}</td>
          <td class="text-right">
            <btn :route="{ name: 'badwords-edit', params: { id: badword.id } }" icon="fa-edit">Edit</btn>
            <btn :route="{ name: 'badwords-single', params: { id: badword.id } }" color="info" icon="fa-eye">Show</btn>
          </td>
        </table-row>
      </template>
    </box-table>
  </div>
</template>

<script>
import BoxSingle from '@/views/components/data/BoxSingle'
import BoxTable from '@/views/components/data/BoxTable'
import Btn from '@/views/components/simple/Btn'
import Badge from '@/views/components/simple/Badge'
import BadgeActive from '@/views/components/simple/BadgeActive'

export default {
  name: 'BadwordsCategoriesSingle',
  metaInfo () {
    return { title: 'Badwords | Category #' + this.id }
  },
  components: {
    BoxSingle,
    BoxTable,
    Btn,
    Badge,
    BadgeActive
  },
  computed: {
    id () {
      return this.$route.params.id
    }
  },
  data () {
    return {
      category: {},
      badwords: []
    }
  }
}
</script>
